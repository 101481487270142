import React, { useRef, useState, useEffect } from "react";
import { API_URL, API_TOKEN, LICENSE_PLATE_MODEL_URL } from "./config";
import { APP_VERSION } from "./version";
import * as tf from "@tensorflow/tfjs";
import * as cocoSsd from "@tensorflow-models/coco-ssd"; // Importa cocoSsd
import Webcam from "react-webcam";
import "./App.css";
import { Fab, Menu, MenuItem, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Icono de menú
import CameraIcon from "@mui/icons-material/CameraFront";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import Popup from "./utils/Popup";
import {
  drawRect,
  generateUniqueId,
  isSimilarDetection,
  calculateDynamicInterval,
  goFullScreen,
} from "./utils/helpers";

// async function loadLicensePlateModel() {
//   return await tf.loadGraphModel(LICENSE_PLATE_MODEL_URL);
// }
function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [cocoNet, setCocoNet] = useState(null);
  // const [licensePlateModel, setLicensePlateModel] = useState(null);
  const [devices, setDevices] = useState([]);
  const [currentDeviceId, setCurrentDeviceId] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [vehicleCrops, setVehicleCrops] = useState([]);
  const [plateCrops, setPlateCrops] = useState([]);

  const [version, setVersion] = useState(APP_VERSION); // Usar la versión desde el archivo
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);

  const [icon, setIcon] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.onload = () => setIcon(img);
    img.src = "/favicon-32x32.png";
  }, []);

  // serviceWorker
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "VERSION") {
          if (event.data.version !== version) {
            setVersion(event.data.version);
            setIsNewVersionAvailable(true);
            setIsUpdatePopupOpen(true);
          }
        }
      });

      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  console.log("New content is available; please refresh.");
                } else {
                  console.log("Content is cached for offline use.");
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error("Error during service worker registration:", error);
        });
    }
  }, [version]);

  const handleUpdate = () => {
    setIsUpdatePopupOpen(false);
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  };

  // Carga ambos modelos al iniciar la aplicación
  useEffect(() => {
    let isCancelled = false;

    async function loadModels() {
      const cocoModel = await cocoSsd.load();
      // const licenseModel = await tf.loadGraphModel(LICENSE_PLATE_MODEL_URL);
      if (!isCancelled) {
        setCocoNet(cocoModel);
        // setLicensePlateModel(licenseModel);
        addLogMessage("Models loaded");
      }
    }

    loadModels();
    // Cleanup function in case the component is unmounted before the models are loaded
    return () => {
      isCancelled = true;
    };
  }, []);

  // Función para cambiar de cámara
  const switchCamera = () => {
    const currentIndex = devices.findIndex(
      (device) => device.deviceId === currentDeviceId
    );
    console.log("currentIndex", currentIndex);
    addLogMessage(currentIndex);
    const nextIndex = (currentIndex + 1) % devices.length; // Esto permite un bucle circular a través de las cámaras
    setCurrentDeviceId(devices[nextIndex]?.deviceId);
  };

  // DETECCION

  // Función para detectar objetos y buscar patentes
  let lastDetectionTime = 0;
  const detect = async () => {
    const currentTime = Date.now();
    if (
      webcamRef.current &&
      webcamRef.current.video.readyState === 4 &&
      cocoNet
      // && licensePlateModel
    ) {
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Ajusta las dimensiones del canvas visible
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      const ctx = canvasRef.current.getContext("2d");

      // Captura el frame actual del video
      ctx.drawImage(video, 0, 0, videoWidth, videoHeight);

      const img = tf.browser.fromPixels(canvasRef.current);
      const cocoPredictions = await cocoNet.detect(img);
      tf.dispose(img);

      // Ahora `drawRect` modificará directamente el canvas visible con las predicciones
      if (icon) {
        drawRect(cocoPredictions, ctx, videoWidth, videoHeight, icon);
      }
      // await detectLicensePlates(
      //   cocoPredictions,
      //   video,
      //   licensePlateModel,
      //   ctx,
      //   setVehicleCrops,
      //   setPlateCrops
      // );

      // Comprueba si ha pasado al menos 1 segundo desde la última ejecución
      if (currentTime - lastDetectionTime < 500) {
        return;
      }
      lastDetectionTime = currentTime;
      detectAndSendVehicles(cocoPredictions, webcamRef);
    }
  };

  const detectionMemoryRef = useRef([]);
  useEffect(() => {
    // Limpia detectionMemory cada 60 segundos
    const intervalId = setInterval(() => {
      detectionMemoryRef.current = [];
      console.log("Limpiando detectionMemory");
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const [lastSentTime, setLastSentTime] = useState(0);
  const detectAndSendVehicles = async (predictions, webcamRef) => {
    const currentTime = Date.now();
    let shouldUpdateLastSentTime = false;

    for (const prediction of predictions) {
      if (prediction.class === "car" && prediction.score > 0.65) {
        const predictionId = generateUniqueId(prediction);
        let existingDetection = detectionMemoryRef.current.find((det) =>
          isSimilarDetection(det, prediction)
        );
        if (existingDetection) {
          // Verifica si ha habido un movimiento significativo o si ha pasado suficiente tiempo
          // if (currentTime - existingDetection.lastSent > calculateDynamicInterval(existingDetection, prediction)) {
          if (currentTime - existingDetection.lastSent > 5000) {
            shouldUpdateLastSentTime = true;
            console.log("Detectado vehículo:", predictionId, "ya lo tenia");
            addLogMessage("Vehículo YA DETECTADO");
            await extractAndSendVehicleImage(prediction, webcamRef);
            existingDetection.lastSent = currentTime;
            existingDetection.bbox = prediction.bbox;
          }
        } else {
          if (currentTime - lastSentTime > 1000) {
            shouldUpdateLastSentTime = true;
            console.log("Detectado vehículo:", predictionId, "nuevo");
            addLogMessage("Vehículo NUEVO");
            await extractAndSendVehicleImage(prediction, webcamRef);
            detectionMemoryRef.current.push({
              id: predictionId,
              bbox: prediction.bbox,
              lastSent: currentTime,
            });
          }
        }
      }
    }

    if (shouldUpdateLastSentTime) {
      setLastSentTime(currentTime);
    }
  };

  // ENVIO
  const sendVehicleData = async (formData) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
        body: formData,
      });
      const data = await response.json();
      addLogMessage("servidor staus:", response.status);
      stopSpinning();
    } catch (error) {
      addLogMessage("Error al enviar la imagen:", error);
      stopSpinning();
    }
  };
  const extractAndSendVehicleImage = async (prediction, webcamRef) => {
    // startSpinning();
    const video = webcamRef.current.video;
    const [x, y, width, height] = prediction.bbox;
    const scale = video.videoWidth / video.offsetWidth; // Ajusta según el escalado del video al canvas

    // Crear un canvas temporal para capturar la imagen del vehículo
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    // Dibujar el segmento del vehículo en el canvas
    ctx.drawImage(
      video,
      x * scale,
      y * scale,
      width * scale,
      height * scale, // Origen en el video
      0,
      0,
      width,
      height // Destino en el canvas
    );

    // const imgTensor = tf.browser.fromPixels(canvas);

    // Llamada a detectLicensePlates para verificar la presencia de una patente
    // const { success, box } = await detectLicensePlates(
    //   imgTensor,
    //   licensePlateModel
    // );
    let success = true;

    if (success) {
      // valida si tiene location
      var lat = 0;
      var lng = 0;
      try {
        if (!location.lat || !location.lng) {
          addLogMessage("No se pudo obtener la ubicación");
          setIsPopupOpen(true);
          stopSpinning();
          return;
        } else {
          lat = location.lat.toString();
          lng = location.lng.toString();
          startSpinning();
        }
      } catch (error) {
        stopSpinning();
        addLogMessage("No se pudo obtener la ubicación");
      }

      // Convertir el canvas a blob
      canvas.toBlob(async (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          // addLogMessage('base64data:', base64data);
          // print image in element "preview"
          document.getElementById("preview").src = base64data;
        };
        // localStorage.setItem("token", "abc1234test");
        // get token
        const token_usr = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("files.photo", blob, "vehicle.jpg");
        formData.append(
          "data",
          JSON.stringify({
            user_token: token_usr,
            lat: lat,
            lng: lng,
          })
        );

        await sendVehicleData(formData);
      }, "image/jpeg");
    } else {
      console.log("No se detectó patente con confianza suficiente.");
    }
    stopSpinning();
  };

  // gps
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const [location, setLocation] = useState({ lat: null, lng: null });

  useEffect(() => {
    const checkGeolocationPermission = async () => {
      // Verificar si la API de Permisos está disponible
      if (!navigator.permissions) {
        addLogMessage(
          "La API de Permisos no está disponible en este navegador."
        );
        return;
      }

      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });

        // Manejar según el estado del permiso
        switch (permissionStatus.state) {
          case "granted":
            // Permiso concedido
            navigator.geolocation.getCurrentPosition((position) => {
              setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            });
            break;
          case "prompt":
            // Permiso aún no concedido o denegado, intentar obtener la ubicación
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setLocation({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
              },
              (error) => {
                addLogMessage("Error al obtener la ubicación: ", error);
                setIsPopupOpen(true);
              }
            );
            break;
          case "denied":
            // Permiso denegado
            addLogMessage("El acceso a la ubicación fue denegado.");
            setIsPopupOpen(true);
            break;
          default:
            addLogMessage(
              "Estado del permiso desconocido: ",
              permissionStatus.state
            );
            setIsPopupOpen(true);
        }

        // Escuchar cambios en el estado del permiso
        permissionStatus.onchange = () => {
          addLogMessage(
            "El estado del permiso de geolocalización cambió a: ",
            permissionStatus.state
          );
        };
      } catch (error) {
        addLogMessage(
          "Error al verificar el estado del permiso de geolocalización: ",
          error
        );
      }
    };

    checkGeolocationPermission();
  }, []);

  // FUNCIONALIDADES
  const handleGoFullScreen = () => {
    if (webcamRef.current && webcamRef.current.video) {
      goFullScreen(webcamRef.current.video);
    }
  };

  useEffect(() => {
    const videoElement = webcamRef.current.video;

    const handleFullScreenChange = () => {
      if (document.fullscreenElement === videoElement) {
        canvasRef.current.width = window.screen.width;
        canvasRef.current.height = window.screen.height;
      } else {
        canvasRef.current.width = videoElement.offsetWidth;
        canvasRef.current.height = videoElement.offsetHeight;
      }
      // Aquí puedes agregar lógica adicional si es necesario para redibujar los bounding boxes
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  // Iniciar la detección
  useEffect(() => {
    // if (cocoNet && licensePlateModel) {
    if (cocoNet) {
      const interval = setInterval(() => {
        detect(); // Se llama sin parámetros ya que cocoNet está en el estado
      }, 200);
      return () => clearInterval(interval);
    }
  }, [cocoNet]);
  // }, [cocoNet, licensePlateModel]);

  const [open, setOpen] = useState(false);
  const handleMenu = () => {
    // si esta abierto lo cierra y si esta cerrado lo abre
    if (open) {
      handleClose();
    } else {
      setOpen(true);
    }
  };

  const disableImageEvents = (e) => {
    e.preventDefault();
  };
  // spinner
  const [isSpinning, setIsSpinning] = useState(false);
  const startSpinning = () => {
    setIsSpinning(true);
  };
  const stopSpinning = () => {
    // delay 1 segundo para que se vea el spinner
    setTimeout(() => {
      setIsSpinning(false);
    }, 3000);
    // setIsSpinning(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [previewSrc, setPreviewSrc] = useState("");

  const [logMessages, setLogMessages] = useState([]);

  const addLogMessage = (message, data = null) => {
    // Añade un nuevo mensaje al estado, manteniendo los mensajes anteriores, como maximo ultimos 3
    setLogMessages((prevMessages) => [...prevMessages, message]);
    if (data) {
      setLogMessages((prevMessages) => [...prevMessages, JSON.stringify(data)]);
    }
    if (logMessages.length > 3) {
      setLogMessages(logMessages.slice(1));
    }
  };

  // instalacion

  // const [isStandalone, setIsStandalone] = useState(false);

  // useEffect(() => {
  //   if (window.matchMedia("(display-mode: standalone)").matches) {
  //     console.log("La aplicación ya está corriendo en modo standalone.");
  //     setIsStandalone(true);
  //   }
  // }, []);
  //   const redirectToInstallPage = () => {
  //     window.location.href = "/install"; // Asegúrate de que esta ruta es correcta en tu enrutamiento
  //   };

  const [promptInstall, setPromptInstall] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [installMessage, setInstallMessage] = useState("");
  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("Evento 'beforeinstallprompt' capturado.");
      setPromptInstall(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    window.addEventListener("appinstalled", () => {
      console.log("La aplicación ha sido instalada.");
      setIsInstallable(false);
      setInstallMessage("La aplicación ya ha sido instalada.");
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  // Obtener dispositivos al iniciar y establecer por defecto la cámara trasera
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      setDevices(videoInputDevices);
      if (videoInputDevices.length) {
        // Selecciona siempre la última cámara disponible
        const lastCamera = videoInputDevices[videoInputDevices.length - 1];
        setCurrentDeviceId(lastCamera.deviceId);
        // numero de camaras
        addLogMessage(
          "Número de cámaras disponibles: " + videoInputDevices.length
        );
        addLogMessage(
          "Index ultima cámara seleccionada: " + (videoInputDevices.length - 1)
        );
      }
      // // Intenta encontrar una cámara trasera por su etiqueta
      // const rearCamera = videoInputDevices.find((device) =>
      //   device.label.toLowerCase().includes("back")

      // );
      // let id_camara = 100;
      // if (rearCamera) {
      //   setCurrentDeviceId(rearCamera.deviceId);
      //   addLogMessage(" camara trasera encontrada");
      //   id_camara = rearCamera.deviceId;
      // } else if (videoInputDevices.length) {
      //   // Si no se encuentra una cámara "trasera", toma el primer dispositivo de video como predeterminado
      //   setCurrentDeviceId(videoInputDevices[0].deviceId);
      //   id_camara = videoInputDevices[0].deviceId;
      //   addLogMessage("camara por defecto");
      // }
      // addLogMessage("camara seleccionada: " + id_camara);
    });
  }, []);

  const handleInstallClick = () => {
    if (!promptInstall) return;

    promptInstall.prompt();
    promptInstall.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("El usuario aceptó la instalación de la A2HS.");
        setInstallMessage("¡Gracias por instalar nuestra app!");
      } else {
        console.log("El usuario rechazó la instalación de la A2HS.");
        setInstallMessage(
          "Instalación rechazada. Puedes instalar la app más tarde si cambias de opinión."
        );
      }
      setPromptInstall(null);
      setIsInstallable(false);
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="camera">
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{
              width: 1920,
              height: 1080,
              deviceId: currentDeviceId
                ? { exact: currentDeviceId }
                : undefined,
            }}
          />
          <div className="camera_overlay"></div>
          <canvas className="camera_canvas" ref={canvasRef} />
        </div>
        <div className="barra_bot"></div>
        <div
          className={`menu-backdrop ${open ? "open" : ""}`}
          onClick={handleClose}
        ></div>

        <div className={`custom-menu ${open ? "open" : ""}`}>
          <div
            className="contenedor-fab-bottom disable-click-effects "
            onClick={handleMenu}
          >
            <div
              className={`fab-button disable-click-effects ${
                isSpinning ? "spinning" : ""
              }`}
              aria-label="add"
            >
              <img
                src="/cmvapp.png"
                alt="Icono Personalizado"
                onContextMenu={disableImageEvents}
                onDragStart={disableImageEvents}
                className="no-select "
              />
            </div>
          </div>
          <div className="menu-layout">
            <div className="menu-options">
              {/* <button
                className="menu-option-button"
                onClick={handleGoFullScreen}
              >
                <FullscreenIcon /> <span>Maximizar</span>
              </button> */}

              <button className="menu-option-button" onClick={switchCamera}>
                <CameraFrontIcon /> <span>Cambiar Cámara</span>
              </button>
              {isInstallable && (
                <button
                  className="menu-option-button"
                  onClick={handleInstallClick}
                >
                  <img
                    className="img-install"
                    src="/install_icon.png"
                    alt="Instalar"
                  />{" "}
                  <span>Instalar</span>
                </button>
              )}
              {/* boton de cerrar sesion, que llama a /logout */}
              <button
                className="menu-option-button"
                onClick={() => {
                  window.location.href = "/logout";
                }}
              >
                <img
                  className="img-install"
                  src="/logout_icon.png"
                  alt="Cerrar Sesión"
                />{" "}
                <span>Cerrar Sesión</span>
              </button>
            </div>
            <div className="preview-container">
              <div className="preview-container-img">
                <img
                  id="preview"
                  src={previewSrc || "/noimages.png"}
                  alt="preview"
                />
              </div>
              {/* <div className="log-box">
                {logMessages.map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </div> */}
            </div>
            <div className="version-box">
              <div className="detail-box">v{version}</div>
            </div>
          </div>
        </div>
      </header>
      <Popup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        title="Permiso de ubicación requerido"
      >
        <p>Por favor, habilita la ubicación por GPS para continuar.</p>
      </Popup>
      <Popup
        isOpen={isUpdatePopupOpen}
        onClose={() => setIsUpdatePopupOpen(false)}
        title="Nueva versión disponible"
      >
        <p>
          Hay una nueva versión de la aplicación disponible. ¿Quieres actualizar
          ahora?
        </p>
        <button onClick={handleUpdate}>Actualizar</button>
      </Popup>
    </div>
  );
}

export default App;

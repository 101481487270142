export const drawRect = (predictions, ctx, videoWidth, videoHeight, icon) => {
  const iconSize = 40; // Tamaño del ícono

  predictions.forEach((prediction) => {
    if (prediction["class"] === "car" && prediction["score"] > 0.7) {
      const [x, y, w, h] = prediction["bbox"];

      // Configuraciones de estilo
      const borderColor = "rgba(255, 87, 34, 0.8)";
      const shadowColor = "rgba(0, 0, 0, 0.5)";

      // Sombras para el efecto elevado
      ctx.shadowColor = shadowColor;
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 3;
      ctx.shadowOffsetY = 3;

      // Dibuja el rectángulo
      ctx.strokeStyle = borderColor;
      ctx.lineWidth = 4;
      ctx.strokeRect(x, y, w, h);

      // Dibuja el ícono en la esquina de la caja
      if (icon) {
        ctx.drawImage(icon, x + w - iconSize - 5, y + 5, iconSize, iconSize);
      }

      // Limpia las configuraciones de sombra para no afectar otros dibujos
      ctx.shadowColor = "transparent";
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
    }
  });
};

export const generateUniqueId = (prediction) => {
  // Implementación de ejemplo: concatena y redondea las coordenadas del bbox
  return `${Math.round(prediction.bbox[0])},${Math.round(
    prediction.bbox[1]
  )},${Math.round(prediction.bbox[2])},${Math.round(prediction.bbox[3])}`;
};

export const isSimilarDetection = (det1, det2) => {
  //   console.log(det1, det2);
  const threshold = 100; // Define un umbral de "similitud" en píxeles
  const distance = Math.sqrt(
    Math.pow(det1.bbox[0] - det2.bbox[0], 2) +
      Math.pow(det1.bbox[1] - det2.bbox[1], 2)
  );
  return distance < threshold;
};

export const calculateDynamicInterval = (existingDetection, newDetection) => {
  // Implementa lógica para ajustar el intervalo basado en el movimiento y otros factores
  return 5000; // Retorna un intervalo en milisegundos
};
export const goFullScreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    /* Firefox */
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    /* IE/Edge */
    element.msRequestFullscreen();
  }
};

//   const detectAndSendVehicles = async (predictions, webcamRef) => {
//     const currentTime = Date.now();
//     predictions.forEach(async (prediction) => {
//       if (prediction.class === "car" && prediction.score > 0.65) {
//         const predictionId = generateUniqueId(prediction); // Considera mejorar esta función
//         let existingDetection = detectionMemory.find((det) =>
//           isSimilarDetection(det, prediction)
//         );

//         if (existingDetection) {
//           // Verifica si ha habido un movimiento significativo o si ha pasado suficiente tiempo
//           if (
//             currentTime - existingDetection.lastSent >
//             calculateDynamicInterval(existingDetection, prediction)
//           ) {
//             await extractAndSendVehicleImage(prediction, webcamRef);
//             existingDetection.lastSent = currentTime;
//             existingDetection.bbox = prediction.bbox; // Actualiza la posición
//           }
//         } else {
//           // Nuevo vehículo detectado
//           // await extractAndSendVehicleImage(prediction, webcamRef);
//           detectionMemory.push({
//             id: predictionId,
//             bbox: prediction.bbox,
//             lastSent: currentTime,
//           });
//         }
//       }
//     });
//   };

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Login.css";

const VALIDATE_URL = "https://techkeeper.cl/publico/usuarios/api/auth/validate";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      // Validar el token con la API
      fetch(VALIDATE_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data==true) {
            localStorage.setItem("token", token);
            history.push("/");
          } else {
            setError("Token inválido");
          }
        })
        .catch((error) => {
          console.error("Error al validar el token:", error);
          setError("Error al validar el token");
        });
    }
  }, [location, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    localStorage.setItem("token", "abc1234test");
    history.push("/");

    // // Aquí harías la solicitud a la API para obtener el token
    // const response = await fetch("https://app.techkeeper.cl/model/test.html", {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ username, password }),
    // });

    // const data = await response.json();

    // if (data.token) {
    //   // Guarda el token en localStorage
    //   localStorage.setItem("token", data.token);
    //   history.push("/");
    // } else {
    //   // Maneja el error
    //   alert("Credenciales incorrectas");
    // }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="cmvapp.png" alt="Techkeeper" className="logo" />
        {error && <p className="error-message">{error}</p>}
        {/* <h2>Inicia Sesión</h2> */}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Correo electrónico"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <button type="submit" className="login-button">
            Ingresar
          </button>
        </form>
        {/* <a href="#" className="reset-password">
          Restablecer Contraseña
        </a> */}
      </div>
      <div className="footer-logo">
        <img src="techkeeper.png" alt="Techkeeper Logo" />
      </div>
    </div>
  );
};

export default Login;

import React from "react";

const Popup = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  // Función para recargar la página
  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="popup-backdrop">
      <div className="popup">
        <h2>{title}</h2>
        {children}
        <button onClick={reload}>Recargar</button>
      </div>
    </div>
  );
};

export default Popup;

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./index.css";
import App from "./App";
import Install from "./Install";
import Login from "./Login"; // Importa el componente de Login
import Logout from "./Logout"; // Importa el componente de Logout
import ProtectedRoute from "./ProtectedRoute"; // Importa la ruta protegida
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Tu propio registro de service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js").then(
      (registration) => {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      (err) => {
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <ProtectedRoute exact path="/" component={App} />
        <ProtectedRoute path="/install" component={Install} />
        {/* Agrega más rutas protegidas aquí si es necesario */}
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     alert("Nueva versión disponible! La aplicación se actualizará.");
//     if (registration.waiting) {
//       registration.waiting.postMessage({ type: "SKIP_WAITING" });
//       window.location.reload();
//     }
//   },
// });

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    // Borrar el token de localStorage
    localStorage.removeItem("token");
    // Redirigir al usuario a la página de inicio de sesión
    history.push("/login");
  }, [history]);

  return (
    <div>
      <p>Cerrando sesión...</p>
    </div>
  );
};

export default Logout;
